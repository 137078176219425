const PATH =
  'fe.pages.organization.view_subscription_users.tables.lr_users_table';

const messages = {
  columns: {
    nameAndEmail: {
      key: `${PATH}.columns.name_and_email`,
      defaultValue: 'Name & Email'
    },
    role: {
      key: `${PATH}.columns.role`,
      defaultValue: 'Role'
    },
    status: {
      key: `${PATH}.columns.status`,
      defaultValue: 'Status'
    },
    invitedOn: {
      key: `${PATH}.columns.invited_on`,
      defaultValue: 'Invited On'
    },
    coach: {
      key: `${PATH}.columns.coach`,
      defaultValue: 'Coach/Group'
    }
  },
  actions: {
    assignCoach: {
      key: `${PATH}.actions.assign_coach`,
      defaultValue: 'Assign to Coach'
    },
    resendInvite: {
      key: `${PATH}.actions.resend_invite`,
      defaultValue: 'Resend Invite ({{count}})'
    },
    unsubscribe: {
      key: `${PATH}.actions.unsubscribe`,
      defaultValue: 'Unsubscribe Users ({{count}})'
    },
    deselect: {
      key: `${PATH}.actions.deselect`,
      defaultValue: 'Deselect All'
    }
  },

  view: {
    key: `${PATH}.view`,
    defaultValue: 'View'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue:
      '{{educators}} educator(s) have been assigned to the coach group'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Error assigning users to coach. Please try again later.'
  },
  invitationsSuccess: {
    key: `${PATH}.invitations_success`,
    defaultValue: 'Invitations resent successfully'
  },
  invitationsError: {
    key: `${PATH}.invitations_error`,
    defaultValue: 'Error resending invitations'
  },
  unsubscribeSuccess: {
    key: `${PATH}.unsubscribe_success`,
    defaultValue: '{{count}} users were unsubscribed'
  },
  unsubscribeError: {
    key: `${PATH}.unsubscribe_error`,
    defaultValue: 'Error unsubscribing user(s). Please try again later.'
  },
  dialog: {
    title: {
      key: `${PATH}.dialog.title`,
      defaultValue: 'Confirm Unsubscribe'
    },
    cancel: {
      key: `${PATH}.dialog.cancel`,
      defaultValue: 'Cancel'
    },
    continue: {
      key: `${PATH}.dialog.continue`,
      defaultValue: 'Continue'
    },
    message01: {
      key: `${PATH}.dialog.message_01`,
      defaultValue: 'You have selected'
    },
    message02: {
      key: `${PATH}.dialog.message_02`,
      defaultValue: '{{count}} educators'
    },
    message03: {
      key: `${PATH}.dialog.message_03`,
      defaultValue: 'in a coach group and'
    },
    message04: {
      key: `${PATH}.dialog.message_04`,
      defaultValue: '{{count}} coaches'
    },
    message05: {
      key: `${PATH}.dialog.message_05`,
      defaultValue:
        'linked to coach groups. Coach groups and educators may need to be recreated.'
    }
  }
};

export default messages;
