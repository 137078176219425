import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Link } from 'react-router-dom';
import { useTableState } from '../utils';
import { useGetCoachGroups } from 'actions/coaches';
import { Table, ResultsSummary } from 'components/Organization/Table';
import { Checkbox } from 'components/Organization/Checkbox';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { composeUser } from 'pages/Organization/Members/utils';
import { Pagination } from 'components/Organization/Pagination';
import { TextButton } from 'components/Organization/TextButton';
import { toastError, toastSuccess } from 'components/Organization/Toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCoachGroup } from 'actions/coachings';

type Coaches = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  educators_count: number;
};

interface LRCoachGroupsTableProps {
  subscriptionId: number;
  searchQuery: string;
}

export function LRCoachGroupsTable({
  searchQuery,
  subscriptionId
}: LRCoachGroupsTableProps) {
  const queryClient = useQueryClient();

  const { isPending, mutate: triggerDeleteCoachGroups } = useMutation({
    mutationFn: ({
      coachIds,
      subscriptionId
    }: {
      coachIds: number[];
      subscriptionId: number;
    }) => deleteCoachGroup(coachIds, subscriptionId),
    onSuccess: (_, { coachIds }) => {
      setSelected([]);
      toastSuccess({ message: i18n.ft(messages.actions.deleteSuccess) });
      queryClient.setQueryData(
        ['coachings', subscriptionId, 'coaches', params],
        {
          ...data,
          coaches: data.coaches.filter(
            (coach: Coaches) => !coachIds.includes(coach.id)
          )
        }
      );
    },
    onError: () => {
      toastError({ message: i18n.ft(messages.actions.deleteError) });
    }
  });

  const {
    state,
    setState: setQueryState,
    setPage: setQueryPage
  } = usePaginatedSearch({
    page: 1,
    per_page: 20,
    query: searchQuery,
    sort_by: null,
    sort_dir: null
  });

  const { selected, setSelected, toggleSelected, setPage, setSortBy } =
    useTableState({
      state,
      search: searchQuery,
      setState: setQueryState,
      setPage: setQueryPage
    });

  const params = {
    ...state,
    sort_by: mapSortBy(state.sort_by)
  };
  const { data, isLoading } = useGetCoachGroups(subscriptionId, params);

  function mapSortBy(sortBy: string | null) {
    switch (sortBy) {
      case 'name':
        return 'coach_name';
      default:
        return sortBy;
    }
  }

  const handleHeaderSelect = () => {
    if (!data || !data.coaches) {
      return;
    }

    if (selected.length <= 0) {
      const allUserIdsOnPage = data.coaches.map((coach: any) => coach.id);
      setSelected(allUserIdsOnPage);
    } else {
      setSelected([]);
    }
  };

  function handleDeleteCoachGroup() {
    if (selected.length <= 0 || isPending) return;

    triggerDeleteCoachGroups({ coachIds: selected, subscriptionId });
  }

  const coachGroups = data?.coaches ?? [];

  return (
    <>
      <ResultsSummary
        perPage={20}
        total={data?.pagination.total_count ?? 0}
        currentPage={data?.pagination.current_page ?? 1}
      />

      <Table
        isLoading={isLoading}
        sortBy={state.sort_by}
        sortDir={state.sort_dir}
        onSort={setSortBy}
        headers={[
          {
            content: (
              <Checkbox
                checked={selected.length > 0}
                onChange={handleHeaderSelect}
                indeterminate={
                  selected.length > 0 && selected.length !== coachGroups.length
                }
              />
            ),
            accessor: 'checkbox'
          },
          {
            content: i18n.ft(messages.columns.nameAndEmail),
            accessor: 'coach_name',
            sortable: true
          },
          {
            content: i18n.ft(messages.columns.educators),
            accessor: 'educators'
          }
        ]}
        data={coachGroups.map((coach: Coaches) => ({
          selected: selected.includes(coach.id),
          checkbox: (
            <Checkbox
              checked={selected.includes(coach.id)}
              onChange={() => toggleSelected(coach.id)}
            />
          ),
          coach_name: composeUser(coach.id, coach.full_name, coach.email),
          educators: (
            <Link
              to={RouteHelpers.getPath(
                'organization-subscriptions-coach-groups',
                {
                  id: subscriptionId,
                  coachId: coach.id
                }
              )}
              className="text-black font-bold underline"
            >
              {i18n.ft(messages.view, { count: coach.educators_count })}
            </Link>
          )
        }))}
        actions={
          selected.length > 0 && (
            <div className="flex gap-6">
              <TextButton
                inverted
                size="small"
                onClick={handleDeleteCoachGroup}
              >
                <i className="fa fa-trash-can fa-solid mr-2" />
                <span>
                  {i18n.ft(messages.actions.delete, {
                    count: selected.length
                  })}
                </span>
              </TextButton>

              <TextButton inverted size="small" onClick={() => setSelected([])}>
                <span>{i18n.ft(messages.actions.deselect)}</span>
              </TextButton>
            </div>
          )
        }
      />

      <div className="mt-6 flex justify-center">
        <Pagination
          page={state.page}
          onPageChange={setPage}
          total={data?.pagination.total_pages ?? 0}
        />
      </div>
    </>
  );
}
