import qs from 'query-string';
import debounce from 'debounce';
import i18n from 'helpers/i18n';
import messages from './messages';
import PageLoader from 'components/PageLoader';
import { useNavigate, useLocation } from 'react-router-dom';
import { roleToText } from 'helpers/role';
import { Table, ResultsSummary } from 'components/Organization/Table';
import { Pagination } from 'components/Organization/Pagination';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { archiveUsersToAccount, useGetAccountMembers } from 'actions/accounts';
import { composeHierarchy, composeUser, formatDate } from './utils';
import { useRef, useState } from 'react';
import RolePopup from 'components/Organization/RolePopup';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetAccountsByArea } from 'actions/accountManager/accounts';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';
import { SecondaryButton } from 'components/Organization/SecondaryButton';
import { Input } from 'components/Organization/Input';
import { Popup } from 'components/Organization/Popup';
import { UserStatusSelect } from './UserStatusSelect';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { BackgroundSVG } from './BackgroundSVG';
import { TextButton } from 'components/Organization/TextButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastSuccess } from 'components/Organization/Toast';
import { Checkbox } from 'components/Organization/Checkbox';
import { UnarchiveModal } from './UnarchiveModal';

import {
  HierarchyModal,
  useAccountHierarchy
} from 'components/Organization/Hierarchy';

const AREA = 'ManageOrganization';

type QueryParams = {
  page: number;
  per_page: number;
  query: string;
  only_deleted: boolean;
  role_id: string[];
  node_id: number[];
  sort_by: string | null;
  sort_dir: 'asc' | 'desc' | null;
};

function Members() {
  const currentUser = useCurrentUser();
  const { data: dataAccountByArea = [] } = useGetAccountsByArea(AREA);
  const accountId = getCurrentAccount(
    currentUser.current_account_id,
    dataAccountByArea
  );
  const location = useLocation();
  const { nodeId } = location.state || [];
  const hierarchy = useAccountHierarchy(accountId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isHierarchyModalOpen, setIsHierarchyModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    state,
    setState: setQueryState,
    setPage: setQueryPage
  } = usePaginatedSearch<QueryParams>({
    page: 1,
    per_page: 20,
    query: '',
    role_id: [],
    node_id: nodeId || [],
    only_deleted: false,
    sort_by: null,
    sort_dir: null
  });

  const { isPending, mutate: archiveSelectedUsers } = useMutation({
    mutationFn: archiveUsersToAccount,
    onSuccess: () => {
      toastSuccess({ message: i18n.ft(messages.membersArchived) });
      refetch();
      setSelectedRows([]);
    }
  });

  const handleFilterButtonClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    filterButtonRef.current?.focus();
  };

  const handleFilterHierarchyButtonClick = () => {
    setIsHierarchyModalOpen(!isHierarchyModalOpen);
  };

  const handleRolesChange = (newRoles: string[]) => {
    setState({ role_id: newRoles });
  };

  const clearRoles = () => {
    setState({ role_id: [] });
  };

  const clearNodes = () => {
    setState({ node_id: [] });
  };

  const handleNodeSelectionChange = (nodes: number[]) => {
    setState({ node_id: nodes });
    setIsHierarchyModalOpen(false);
  };

  const handleRowSelect = (userId: number) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(userId)
        ? prevSelectedRows.filter(id => id !== userId)
        : [...prevSelectedRows, userId]
    );
  };

  const handleHeaderSelect = () => {
    if (!data || !data.users) return;

    if (selectedRows.length <= 0) {
      const allUserIdsOnPage = data.users.map(user => user.id);
      setSelectedRows(allUserIdsOnPage);
    } else {
      setSelectedRows([]);
    }
  };

  function setState(newState: Partial<QueryParams>) {
    setQueryState(newState);
    setSelectedRows([]);
  }

  function setPage(newPage: number) {
    setQueryPage(newPage);
    setSelectedRows([]);
  }

  const sortMapping: { [key: string]: string } = {
    user: 'user_name',
    name: 'user_name',
    email: 'user_email',
    last_login: 'user_last_login'
  };

  const tableParams = {
    ...state,
    sort_by:
      state.sort_by && state.sort_by in sortMapping
        ? sortMapping[state.sort_by]
        : state.sort_by
  };

  const baseParams = {
    ...tableParams,
    page: undefined,
    per_page: undefined
  };

  const { data, isLoading, refetch } = useGetAccountMembers(
    accountId,
    tableParams
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ query: event.target.value });
  };

  const handleSort = (header: string) => {
    if (header === state.sort_by && state.sort_dir === 'asc') {
      setState({ sort_dir: 'desc' });
    } else if (header === state.sort_by && state.sort_dir === 'desc') {
      setState({ sort_by: null, sort_dir: null });
    } else {
      setState({ sort_by: header, sort_dir: 'asc' });
    }
  };

  const handleArchive = () => {
    if (isPending || selectedRows.length === 0) {
      return;
    }

    archiveSelectedUsers({ accountId, params: { user_ids: selectedRows } });
  };

  const handleUnarchive = () => {
    toastSuccess({
      message: i18n.ft(messages.membersReactivated, {
        count: selectedRows.length
      })
    });

    queryClient.setQueryData(
      ['ca', 'accounts', accountId, 'members', tableParams],
      (currentData: any) => {
        return {
          ...currentData,
          users: currentData.users.filter(
            (user: any) => !selectedRows.includes(user.id)
          )
        };
      }
    );

    setSelectedRows([]);
    setIsModalOpen(false);
  };

  const tableHeaders = data?.hierarchy_present
    ? [
        {
          content: (
            <Checkbox
              onChange={handleHeaderSelect}
              checked={selectedRows.length > 0}
              indeterminate={selectedRows.length > 0}
            />
          ),
          accessor: 'checkbox'
        },
        {
          content: i18n.ft(messages.nameAndEmail),
          accessor: 'user',
          sortable: true
        },
        {
          content: i18n.ft(messages.hierarchyAssociation),
          accessor: 'hierarchy',
          className: 'max-w-md',
          filter: (
            <div className="flex gap-2">
              <button
                onClick={handleFilterHierarchyButtonClick}
                className={`font-bold ${isHierarchyModalOpen ? 'text-action' : 'text-black'}`}
                aria-haspopup="true"
                aria-expanded={isHierarchyModalOpen}
                aria-label={i18n.ft(messages.filterHierarchy)}
              >
                <i className="fa-solid fa-bars-filter fa-sm"></i>
              </button>
              {isHierarchyModalOpen && (
                <HierarchyModal
                  {...hierarchy}
                  title={i18n.ft(messages.filterHierarchy)}
                  buttonText={i18n.ft(messages.saveButton)}
                  isOpen={isHierarchyModalOpen}
                  selectedNodes={state.node_id}
                  onSave={handleNodeSelectionChange}
                  onClose={handleFilterHierarchyButtonClick}
                />
              )}
              {state.node_id.length > 0 && (
                <div>
                  <button
                    onClick={clearNodes}
                    className="text-action cursor-pointer"
                    aria-label={i18n.ft(messages.clearNodes)}
                  >
                    <i className="fa-regular fa-circle-xmark fa-sm" />
                  </button>
                </div>
              )}
            </div>
          )
        },
        {
          content: i18n.ft(messages.role),
          accessor: 'role',
          filter: (
            <div className="flex gap-2">
              <button
                ref={filterButtonRef}
                onClick={handleFilterButtonClick}
                className={`font-bold ${
                  isPopupOpen ? 'text-action' : 'text-black'
                }`}
                aria-haspopup="true"
                aria-expanded={isPopupOpen}
                aria-label={i18n.ft(messages.filterRoles)}
              >
                <i className="fa-solid fa-bars-filter fa-sm"></i>
              </button>
              {isPopupOpen && (
                <RolePopup
                  accountId={accountId}
                  isOpen={isPopupOpen}
                  onClose={handlePopupClose}
                  onChange={handleRolesChange}
                  selectedValues={state.role_id}
                />
              )}
              {state.role_id.length > 0 && (
                <div>
                  <button
                    onClick={clearRoles}
                    className="text-action cursor-pointer"
                    aria-label={i18n.ft(messages.clearRoles)}
                  >
                    <i className="fa-regular fa-circle-xmark" />
                  </button>
                </div>
              )}
            </div>
          )
        },
        {
          content: i18n.ft(messages.lastLogin),
          accessor: 'last_login',
          sortable: true
        }
      ]
    : [
        {
          content: (
            <Checkbox
              onChange={handleHeaderSelect}
              checked={selectedRows.length > 0}
              indeterminate={selectedRows.length > 0}
            />
          ),
          accessor: 'checkbox'
        },
        { content: i18n.ft(messages.name), accessor: 'name', sortable: true },
        { content: i18n.ft(messages.email), accessor: 'email', sortable: true },
        { content: i18n.ft(messages.role), accessor: 'role' },
        {
          content: i18n.ft(messages.lastLogin),
          accessor: 'last_login',
          sortable: true
        }
      ];

  const tableData = data?.users.map(user => {
    return data?.hierarchy_present
      ? {
          selected: selectedRows.includes(user.id),
          checkbox: (
            <Checkbox
              checked={selectedRows.includes(user.id)}
              onChange={() => handleRowSelect(user.id)}
            />
          ),
          user: composeUser(user.id, user.name, user.email),
          hierarchy: composeHierarchy(user.hierarchy),
          role: roleToText(user.role),
          last_login: formatDate(user.last_login)
        }
      : {
          selected: selectedRows.includes(user.id),
          checkbox: (
            <Checkbox
              checked={selectedRows.includes(user.id)}
              onChange={() => handleRowSelect(user.id)}
            />
          ),
          name: composeUser(user.id, user.name),
          email: user.email,
          role: roleToText(user.role),
          last_login: formatDate(user.last_login)
        };
  });

  const membersCount = data?.pagination.total_count;

  const membersCsvUrl = qs.stringifyUrl(
    {
      url: `/api/v2/ca/accounts/${accountId}/members.csv`,
      query: baseParams
    },
    { arrayFormat: 'bracket' }
  );

  function mapSelectedRowsToUsers() {
    if (!data || !data.users) return [];

    return data.users.filter(user => selectedRows.includes(user.id));
  }

  return (
    <div className="my-8 relative isolate">
      <BackgroundSVG className="absolute left-0 right-0 -mx-6 xl:-mx-14 z-0" />

      <UnarchiveModal
        open={isModalOpen}
        accountId={accountId}
        members={mapSelectedRowsToUsers()}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleUnarchive}
      />

      <div className="z-10 relative">
        <div className="pt-6 pb-4 md:pb-6">
          <h2 className="font-sans font-bold text-3xl text-white">
            {i18n.ft(messages.members)}
          </h2>
        </div>

        <div className="p-6 pb-12 bg-white rounded-2xl">
          <div className="flex justify-between items-center">
            <UserStatusSelect
              value={state.only_deleted}
              onChange={value => setState({ only_deleted: value })}
              options={[
                { value: false, text: i18n.ft(messages.activeMembers) },
                { value: true, text: i18n.ft(messages.archivedMembers) }
              ]}
            />

            <div className="flex gap-4 ml-auto">
              <div className="relative">
                <Input
                  type="search"
                  className="max-w-94 min-w-80"
                  onChange={debounce(handleSearch, 300)}
                  placeholder={i18n.ft(messages.searchPlaceholder)}
                  aria-label={i18n.ft(messages.searchPlaceholder)}
                />
                <i className="fa fa-search absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
              </div>

              <Popup label={i18n.ft(messages.downloadMemberList)}>
                <a className="flex" href={membersCsvUrl} download>
                  <SecondaryButton size="small">
                    <i className="fa-solid fa-download" />
                  </SecondaryButton>
                </a>
              </Popup>

              <PrimaryButton
                icon="fa-solid fa-plus"
                onClick={() => navigate('/organization/add-members')}
              >
                {i18n.ft(messages.addMembers)}
              </PrimaryButton>
            </div>
          </div>

          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              {tableData && (
                <>
                  <ResultsSummary
                    perPage={20}
                    total={data?.pagination.total_count ?? 0}
                    currentPage={data?.pagination.page ?? 1}
                  />

                  <Table
                    headers={tableHeaders}
                    data={tableData}
                    sortBy={state.sort_by}
                    sortDir={state.sort_dir}
                    onSort={handleSort}
                    actions={
                      selectedRows.length > 0 ? (
                        <div className="flex gap-6">
                          {state.only_deleted ? (
                            <TextButton
                              inverted
                              size="small"
                              onClick={() => setIsModalOpen(true)}
                            >
                              <i className="fa-solid fa-rotate-right mr-2" />
                              <span>
                                {i18n.ft(messages.activateSelected, {
                                  count: selectedRows.length
                                })}
                              </span>
                            </TextButton>
                          ) : (
                            <TextButton
                              inverted
                              size="small"
                              onClick={handleArchive}
                            >
                              <i className="fa-solid fa-folder-open" />
                              <span className="ml-2">
                                {i18n.ft(messages.archiveSelected, {
                                  count: selectedRows.length
                                })}
                              </span>
                            </TextButton>
                          )}

                          <TextButton
                            inverted
                            size="small"
                            onClick={() => setSelectedRows([])}
                          >
                            {i18n.ft(messages.deselectAll)}
                          </TextButton>
                        </div>
                      ) : null
                    }
                  />

                  <div className="mt-6 flex justify-center">
                    <Pagination
                      page={state.page}
                      onPageChange={setPage}
                      total={data?.pagination.total_pages ?? 0}
                      label={i18n.ft(messages.totalMembers, {
                        count: membersCount
                      })}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Members;
