const PATH = 'fe.pages.organization.members';

const messages = {
  addMembers: {
    key: `${PATH}.add_members`,
    defaultValue: 'Add Members'
  },
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Name'
  },
  members: {
    key: `${PATH}.members`,
    defaultValue: 'Members'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  nameAndEmail: {
    key: `${PATH}.name_and_email`,
    defaultValue: 'Name & Email'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search name or email'
  },
  hierarchyAssociation: {
    key: `${PATH}.hierarchy_association`,
    defaultValue: 'Hierarchy Association'
  },
  role: {
    key: `${PATH}.role`,
    defaultValue: 'Role'
  },
  lastLogin: {
    key: `${PATH}.last_login`,
    defaultValue: 'Last Login'
  },
  totalMembers: {
    key: `${PATH}.total_members`,
    defaultValue: 'Total: {{count}} members'
  },
  filterRoles: {
    key: `${PATH}.filter_roles`,
    defaultValue: 'Filter roles'
  },
  clearRoles: {
    key: `${PATH}.clear_roles`,
    defaultValue: 'Clear selected roles'
  },
  clearNodes: {
    key: `${PATH}.clear_nodes`,
    defaultValue: 'Clear selected hierarchy associations'
  },
  downloadMemberList: {
    key: `${PATH}.download_members_list`,
    defaultValue: 'Download Members List'
  },
  filterHierarchy: {
    key: `${PATH}.filter_hierarchy`,
    defaultValue: 'Filter by Hierarchy'
  },
  saveButton: {
    key: `${PATH}.save_button`,
    defaultValue: 'Save & Close'
  },
  viewing: {
    key: `${PATH}.viewing`,
    defaultValue: 'Viewing'
  },
  activeMembers: {
    key: `${PATH}.active_members`,
    defaultValue: 'Active Members'
  },
  archivedMembers: {
    key: `${PATH}.archived_members`,
    defaultValue: 'Archived Members'
  },
  membersArchived: {
    key: `${PATH}.members_archived`,
    defaultValue: 'Members Archived'
  },
  membersReactivated: {
    key: `${PATH}.members_reactivated`,
    defaultValue: '{{count}} Members have been reactivated'
  },
  archiveSelected: {
    key: `${PATH}.archive_selected`,
    defaultValue: 'Archive Selected ({{count}})'
  },
  activateSelected: {
    key: `${PATH}.activate_selected`,
    defaultValue: 'Re-Activate Selected ({{count}})'
  },
  deselectAll: {
    key: `${PATH}.deselect_all`,
    defaultValue: 'Deselect All'
  },
  modal: {
    title: {
      key: `${PATH}.modal.title`,
      defaultValue: 'Re-activate Members'
    },
    description: {
      key: `${PATH}.modal.description`,
      defaultValue:
        'You are reactivating {{count}} members. Each member will receive an email explaining this change.'
    },
    cancel: {
      key: `${PATH}.modal.cancel`,
      defaultValue: 'Cancel'
    },
    proceed: {
      key: `${PATH}.modal.proceed`,
      defaultValue: 'Proceed'
    }
  }
};

export default messages;
