import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import useCurrentUser from 'hooks/useCurrentUser';
import { useState } from 'react';
import { roleToText } from 'helpers/role';
import { InviteUserForm } from './InviteUserForm';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSubscription } from 'actions/subscriptions';
import { SelectOption } from 'components/Organization/Select';
import { toastSuccess } from 'components/Organization/Toast';
import { inviteUserToAccount } from 'actions/learningResources';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { TextButton } from 'components/Organization/TextButton';
import { IconButton } from 'components/Organization/IconButton';
import RouteHelpers from 'helpers/routes';
import LeaveModal from 'components/Organization/LeaveModal';
import CSVMemberUploadModal from 'components/Organization/CSVMemberUploadModal';

import {
  useForm,
  useFieldArray,
  FormProvider,
  useWatch
} from 'react-hook-form';

const schema = yup.object({
  members: yup.array().of(
    yup.object({
      email: yup
        .string()
        .email(i18n.ft(messages.errors.email))
        .required(i18n.ft(messages.errors.required))
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, i18n.ft(messages.errors.email)),
      role: yup.string().required(i18n.ft(messages.errors.required))
    })
  )
});

function parseError(error: any) {
  return error?.response?.data ?? [{}];
}

type Member = {
  email: string;
  role: string;
};

interface InviteUsersProps {
  /** Current account id. */
  accountId: number;

  /** Subscription id. */
  subscriptionId: number;

  /** List of roles formatted for select component. */
  roles: SelectOption[];
}

function InviteUsers({ accountId, subscriptionId, roles }: InviteUsersProps) {
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      members: [{ email: '', role: '' }]
    }
  });

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors }
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'members'
  });

  const members = useWatch({ name: 'members', control });

  const { isPending, mutate: triggerInviteUsers } = useMutation({
    mutationFn: inviteUserToAccount,
    onSuccess: ({ data }) => {
      const routeName = 'organization-subscriptions-users';

      navigate(RouteHelpers.getPath(routeName, { id: subscriptionId }));
      toastSuccess({
        message: i18n.ft(messages.userInvited, { count: data.length })
      });
    },
    onError: error => {
      const errors = parseError(error);

      errors.forEach((userError: any, index: number) => {
        if (userError) {
          if (userError.email) {
            setError(`members.${index}.email`, { message: userError.email });
          }

          if (userError.account_user) {
            setError(`root.${index}`, { message: userError.account_user });
          }
        }
      });
    }
  });

  function groupEmailsByRole(members: Member[]) {
    const emailsByRole: { [key: string]: string[] } = {};

    members.forEach(member => {
      const roleKey = member.role ?? 'none';

      if (!emailsByRole[roleKey]) {
        emailsByRole[roleKey] = [];
      }

      emailsByRole[roleKey].push(member.email);
    });

    return Object.keys(emailsByRole).map(roleKey => ({
      emails: emailsByRole[roleKey],
      role: roleKey === 'none' ? null : roleKey
    }));
  }

  function handleFormSubmit(data: any) {
    if (isPending) {
      return;
    }

    triggerInviteUsers({
      subscriptionId: subscriptionId,
      params: { memberships: groupEmailsByRole(data.members) }
    });
  }

  const areUsersReadyToInvite = () => {
    const hasErrors =
      Array.isArray(errors.members) && errors.members.some(error => error);

    return (
      !hasErrors && (members ?? []).every(member => member.email && member.role)
    );
  };

  const handleOpenModal = () => {
    if ((members ?? []).every(m => !m.role && !m.email)) {
      handleLeave();
    } else {
      setModalOpen(true);
    }
  };

  const handleLeave = () => {
    reset();
    setModalOpen(false);
    navigate(-1);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseCSVModal = () => {
    setIsCSVModalOpen(false);
  };

  const handleAddUserForm = () => {
    append({ email: '', role: '' });
  };

  return (
    <FormProvider {...methods}>
      <div className="py-8 px-4 md:px-6 xl:px-10 max-w-7xl mx-auto">
        <LeaveModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onLeave={handleLeave}
        />

        <CSVMemberUploadModal
          isOpen={isCSVModalOpen}
          onClose={handleCloseCSVModal}
        />

        <div className="flex items-center justify-between py-4">
          <div className="flex items-center gap-2">
            <IconButton
              label={i18n.ft(messages.goBack)}
              icon="fa-solid fa-arrow-left"
              onClick={handleOpenModal}
            />

            <h2 className="font-sans font-bold text-black text-3xl">
              {i18n.ft(messages.new)}
            </h2>
          </div>

          <PrimaryButton
            type="button"
            icon="fa-solid fa-arrow-up-from-bracket"
            onClick={() => setIsCSVModalOpen(true)}
          >
            {i18n.ft(messages.upload)}
          </PrimaryButton>
        </div>

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col gap-4"
        >
          {fields.map((item, index) => (
            <InviteUserForm
              key={item.id}
              index={index}
              roles={roles}
              remove={index !== 0 ? () => remove(index) : undefined}
              accountId={accountId}
              subscriptionId={subscriptionId}
            />
          ))}

          <div className="flex items-center justify-between">
            <TextButton size="small" type="button" onClick={handleAddUserForm}>
              <span className="fa-solid fa-plus mr-2"></span>
              <span>{i18n.ft(messages.addAnotherUser)}</span>
            </TextButton>

            <PrimaryButton
              type="submit"
              disabled={!areUsersReadyToInvite() || isPending}
              className={`disabled:bg-[#EBEFF4] disabled:cursor-not-allowed disabled:text-navy-200`}
            >
              {i18n.ft(messages.inviteUsers)}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </FormProvider>
  );
}

const lrRoles: SelectOption[] = [
  { value: 'lr_admin', text: roleToText('lr_admin') },
  { value: 'lr_educator', text: roleToText('lr_educator') },
  { value: 'lr_coach', text: roleToText('lr_coach') }
];

const dcRoles: SelectOption[] = [
  { value: 'data_entry', text: roleToText('data_entry') },
  { value: 'observer', text: roleToText('observer') },
  { value: 'supervisor', text: roleToText('supervisor') }
];

function InviteUsersController() {
  const currentUser = useCurrentUser();
  const accountId = currentUser.current_account_id ?? 0;
  const params = useParams();
  const subscriptionId = Number(params.id);

  const { data: subscription } = useGetSubscription(subscriptionId);

  if (!subscription) {
    return null;
  }

  const roles =
    subscription.product.name === 'learning_resources' ? lrRoles : dcRoles;

  return (
    <InviteUsers
      roles={roles}
      accountId={accountId}
      subscriptionId={subscriptionId}
    />
  );
}

export default InviteUsersController;
