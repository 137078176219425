import { AgeLevel } from 'types/api/AgeLevel';
import messages from './messages';
import i18n from 'helpers/i18n';

interface AuthorizedTrainingAgeLevelsProps {
  ageLevels?: AgeLevel[];
  groupName: string;
}

const formatMessage = (ageLevels: AgeLevel[], groupName: string) => {
  if (ageLevels.length < 1) return;

  const ageLevel = ageLevels[0];
  if (groupName !== 'observer trainer') {
    return i18n.ft(messages.trainableAgeLevelsCoaching, {
      ageLevel: ageLevel.name
    });
  }
  if (ageLevels.length === 2) {
    const AgeLevelAdditional = ageLevels[1];
    return i18n.ft(messages.trainableAgeLevelsMultiple, {
      ageLevel: ageLevel.name,
      ageLevelAdditional: AgeLevelAdditional.name
    });
  }
  return i18n.ft(messages.trainableAgeLevelsSingle, {
    ageLevel: ageLevel.name
  });
};

function AuthorizedTrainingAgeLevels({
  ageLevels,
  groupName
}: AuthorizedTrainingAgeLevelsProps) {
  if (!ageLevels || ageLevels.length < 1) return <></>;
  return (
    <div>
      <div className="mb-1">{formatMessage(ageLevels, groupName)}</div>
    </div>
  );
}

export default AuthorizedTrainingAgeLevels;
