import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTableState } from '../utils';
import { roleToText } from 'helpers/role';
import { lrRoles, statusList } from '../../utils';
import { createCoachings } from 'actions/coachings';
import { resendInvitations } from 'actions/customers';
import { Checkbox } from 'components/Organization/Checkbox';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { TextButton } from 'components/Organization/TextButton';
import { Pagination } from 'components/Organization/Pagination';
import { StatusBadge } from 'components/Organization/StatusBadge';
import { toastError, toastSuccess } from 'components/Organization/Toast';
import AssignCoachModal from 'components/Organization/AssignCoachModal';
import { composeUser, formatDate } from 'pages/Organization/Members/utils';
import { getUnsubscribeCount } from 'actions/subscriptions';
import { UnsubscribeAlertDialog } from './UnsubscribeDialog';
import { useMutation } from '@tanstack/react-query';

import {
  Table,
  ColumnFilter,
  ResultsSummary
} from 'components/Organization/Table';

import {
  removeUsersFromSubscription,
  useGetSubscriptionMembershipTrackers
} from 'actions/membershipTrackers';

type QueryParams = {
  page: number;
  per_page: number;
  query: string;
  role: string[];
  status: string[];
  sort_by: string | null;
  sort_dir: 'asc' | 'desc' | null;
};

interface LRUsersTableProps {
  subscriptionId: number;
  searchQuery: string;
}

export function LRUsersTable({
  subscriptionId,
  searchQuery
}: LRUsersTableProps) {
  const [isAssignCoachModalOpen, setIsAssignCoachModalOpen] = useState(false);
  const [isInviteLoading, setIsInviteLoading] = useState(false);
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(false);

  const [rolesWithWarnings, setRolesWithWarnings] = useState({
    lr_coach: 0,
    lr_educator: 0
  });

  const { isPending: isUnsubscribing, mutate: triggerUnsubscribeUsers } =
    useMutation({
      mutationFn: ({
        subscriptionId,
        trackerIds
      }: {
        subscriptionId: number;
        trackerIds: number[];
      }) => removeUsersFromSubscription(subscriptionId, trackerIds),
      onSuccess: (data, { trackerIds }) => {
        setSelected([]);
        toastSuccess({
          message: i18n.ft(messages.unsubscribeSuccess, {
            count: trackerIds.length
          })
        });
        refetch();
      },
      onError: () => {
        toastError({
          message: i18n.ft(messages.unsubscribeError)
        });
      }
    });

  const {
    state,
    setState: setQueryState,
    setPage: setQueryPage
  } = usePaginatedSearch<QueryParams>({
    page: 1,
    per_page: 20,
    query: searchQuery,
    role: [],
    status: [],
    sort_by: null,
    sort_dir: null
  });

  const {
    selected,
    setSelected,
    toggleSelected,
    setState,
    setPage,
    setSortBy
  } = useTableState({
    state,
    search: searchQuery,
    setState: setQueryState,
    setPage: setQueryPage
  });

  const { data, isLoading, refetch } = useGetSubscriptionMembershipTrackers(
    subscriptionId,
    state
  );

  const handleHeaderSelect = () => {
    if (!data || !data.membership_trackers) {
      return;
    }

    if (selected.length <= 0) {
      const allTrackerIdsOnPage = data.membership_trackers.map(
        tracker => tracker.id
      );
      setSelected(allTrackerIdsOnPage);
    } else {
      setSelected([]);
    }
  };

  function getRolesCount() {
    const trackers = data?.membership_trackers ?? [];

    const countByRole = (role: string) =>
      trackers.filter(t => t.role === role && selected.includes(t.id)).length;

    return {
      admins: countByRole('lr_admin'),
      coaches: countByRole('lr_coach'),
      educators: countByRole('lr_educator')
    };
  }

  function assignToCoach(coachId: number) {
    if (selected.length <= 0) {
      return;
    }

    const trackers = data?.membership_trackers ?? [];

    const coachings = trackers
      .filter(t => selected.includes(t.id) && t.role === 'lr_educator')
      .map(t => ({ coach_id: coachId, educator_id: t.user_id }));

    createCoachings(subscriptionId, coachings)
      .then(() => {
        setSelected([]);
        setIsAssignCoachModalOpen(false);
        toastSuccess({
          message: i18n.ft(messages.success, {
            educators: getRolesCount().educators
          })
        });
      })
      .catch(() => {
        toastError({
          message: i18n.ft(messages.error)
        });
      });
  }

  const getEmailsFromSelectedUsers = () => {
    if (!data || !data.membership_trackers) return [];

    return data.membership_trackers
      .filter(tracker => selected.includes(tracker.id))
      .map(tracker => tracker.email);
  };

  function handleResendInvitation() {
    if (isInviteLoading) {
      return;
    }

    setIsInviteLoading(true);
    resendInvitations(subscriptionId, getEmailsFromSelectedUsers())
      .then(() => {
        toastSuccess({
          message: i18n.ft(messages.invitationsSuccess)
        });
      })
      .catch(() => {
        toastError({
          message: i18n.ft(messages.invitationsError)
        });
      })
      .finally(() => {
        setSelected([]);
        setIsInviteLoading(false);
      });
  }

  async function handleUnsubscribeUsers() {
    const trackers = data?.membership_trackers ?? [];

    const userIds = trackers
      .filter(t => selected.includes(t.id))
      .map(t => t.user_id);

    const { data: roleCount } = await getUnsubscribeCount(subscriptionId, {
      user_id: userIds
    });

    if (roleCount.lr_coach > 0 || roleCount.lr_educator > 0) {
      setRolesWithWarnings(roleCount);
      setIsUnsubscribeOpen(true);
    } else {
      unsubscribeUsers();
    }
  }

  function unsubscribeUsers() {
    if (isUnsubscribing) {
      return;
    }

    triggerUnsubscribeUsers({ subscriptionId, trackerIds: selected });
  }

  const trackers = data?.membership_trackers ?? [];

  return (
    <>
      <AssignCoachModal
        isOpen={isAssignCoachModalOpen}
        onClose={() => {
          setIsAssignCoachModalOpen(false);
        }}
        onAssign={(coachId: number) => {
          assignToCoach(coachId);
        }}
        roles={getRolesCount()}
      />

      <UnsubscribeAlertDialog
        open={isUnsubscribeOpen}
        onOpenChange={setIsUnsubscribeOpen}
        onConfirm={unsubscribeUsers}
        rolesWithWarnings={rolesWithWarnings}
      />

      <ResultsSummary
        perPage={20}
        total={data?.pagination.total_count ?? 0}
        currentPage={data?.pagination.current_page ?? 1}
      />

      <Table
        isLoading={isLoading}
        sortBy={state.sort_by}
        sortDir={state.sort_dir}
        onSort={setSortBy}
        headers={[
          {
            content: (
              <Checkbox
                checked={selected.length > 0}
                onChange={handleHeaderSelect}
                indeterminate={
                  selected.length > 0 && selected.length !== trackers.length
                }
              />
            ),
            accessor: 'checkbox'
          },
          {
            content: i18n.ft(messages.columns.nameAndEmail),
            accessor: 'name',
            sortable: true
          },
          {
            content: (
              <ColumnFilter
                values={state.role}
                options={lrRoles}
                onChange={role => setState({ role })}
                name={i18n.ft(messages.columns.role)}
              />
            ),
            accessor: 'role'
          },
          {
            content: (
              <ColumnFilter
                values={state.status}
                options={statusList}
                onChange={status => setState({ status })}
                name={i18n.ft(messages.columns.status)}
              />
            ),
            accessor: 'invite_status'
          },
          {
            content: i18n.ft(messages.columns.invitedOn),
            accessor: 'invited_on',
            sortable: true
          },
          {
            content: i18n.ft(messages.columns.coach),
            accessor: 'coach'
          }
        ]}
        data={trackers.map(tracker => ({
          selected: selected.includes(tracker.id),
          checkbox: (
            <Checkbox
              checked={selected.includes(tracker.id)}
              onChange={() => toggleSelected(tracker.id)}
            />
          ),
          name: composeUser(tracker.user_id, tracker.name, tracker.email),
          role: roleToText(tracker.role),
          invited_on: formatDate(tracker.invited_on),
          invite_status: <StatusBadge status={tracker.invite_status} />,
          coach:
            tracker.role === 'lr_coach' ? (
              <Link
                to={RouteHelpers.getPath(
                  'organization-subscriptions-coach-groups',
                  {
                    id: subscriptionId,
                    coachId: tracker.user_id
                  }
                )}
                className="text-black font-bold underline"
              >
                {i18n.ft(messages.view)}
              </Link>
            ) : (
              '--'
            )
        }))}
        actions={
          selected.length > 0 && (
            <div className="flex gap-6">
              <TextButton
                inverted
                size="small"
                onClick={() => {
                  setIsAssignCoachModalOpen(true);
                }}
              >
                <i className="fa-solid fa-plus mr-2" />
                <span>{i18n.ft(messages.actions.assignCoach)}</span>
              </TextButton>

              <TextButton
                inverted
                size="small"
                onClick={handleResendInvitation}
              >
                <i className="fa-regular fa-paper-plane-top mr-2" />
                <span>
                  {i18n.ft(messages.actions.resendInvite, {
                    count: selected.length
                  })}
                </span>
              </TextButton>

              <TextButton
                inverted
                size="small"
                onClick={handleUnsubscribeUsers}
              >
                <i className="fa-regular fa-folder-arrow-down mr-2" />
                <span>
                  {i18n.ft(messages.actions.unsubscribe, {
                    count: selected.length
                  })}
                </span>
              </TextButton>

              <TextButton inverted size="small" onClick={() => setSelected([])}>
                <span>{i18n.ft(messages.actions.deselect)}</span>
              </TextButton>
            </div>
          )
        }
      />

      <div className="mt-6 flex justify-center">
        <Pagination
          page={state.page}
          onPageChange={setPage}
          total={data?.pagination.total_pages ?? 0}
        />
      </div>
    </>
  );
}
