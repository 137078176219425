import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const Container = styled.div`
  margin-bottom: 16px;
  background-color: ${p => p.theme.colors['white']};
  border-top: 1px solid ${p => p.theme.colors['neutral']['200']};
  border-bottom: 1px solid ${p => p.theme.colors['neutral']['200']};

  @media screen and (min-width: 768px) {
    margin: 0 0 16px;
    border-radius: 6px;
    border: 1px solid ${p => p.theme.colors['neutral']['200']};
  }

  @media screen and (min-width: 1024px) {
    margin: 0 0 16px;
  }
`;

export const DetailsContainer = styled.div`
  padding: 12px 16px;

  @media screen and (min-width: 640px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width: 768px) {
    padding: 16px 24px;
  }
`;

export const DetailsMain = styled.div`
  @media screen and (min-width: 768px) {
    min-width: 340px;
    max-width: 340px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: baseline;
  justify-content: space-between;
  border-top: 1px solid ${p => p.theme.colors['neutral']['200']};

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
    padding: 16px 24px;
  }
`;

export const HistoryContainer = styled.div`
  padding: 12px 16px;
  border-top: 1px solid ${p => p.theme.colors['neutral']['200']};

  @media screen and (min-width: 768px) {
    padding: 16px 24px;
  }
`;

export const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-wrap: nowrap;
  color: ${p => p.theme.colors['neutral']['900']};
`;

export const StatusContainer = styled.div`
  display: none;
  margin-right: auto;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const StatusContainerMobile = styled.div`
  margin-top: 12px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const ExpirationContainer = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    min-width: 80px;
  }
`;

export const ExpirationLabel = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
  color: ${p => p.theme.colors['neutral']['500']};
`;

export const ExpirationDate = styled.div`
  font-weight: 600;
  color: ${p => p.theme.colors['neutral']['900']};
`;

export const ExpirationTime = styled.div`
  font-weight: 600;
  color ${p => p.theme.colors['neutral']['500']};
`;

export const DownloadContainer = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const DownloadContainerMobile = styled.div`
  margin-right: 32px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const DownloadLabel = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
  color: ${p => p.theme.colors['neutral']['500']};
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const TextButton = styled.button`
  font-size: 16px;
  font-weight: bold;
  color: ${p => p.theme.colors['primary']['700']};
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  outline-offset: 4px;

  &:hover {
    cursor: pointer;
  }
`;

export const TagContainer = styled.div`
  margin-top: 8px;
  margin-bottom: -8px;
`;

export const RenewContainer = styled.div`
  min-width: 100px;

  @media screen and (min-width: 640px) {
    margin-top: 0;
    margin-left: 16px;
  }
`;

export const RenewButton = styled.div`
  margin-top: 8px;
`;

export const TableHeaderCell = styled(Table.HeaderCell)`
  &&& {
    color: ${p => p.theme.colors['neutral']['500']};
  }
`;

export const TableBody = styled(Table.Body)`
  color: ${p => p.theme.colors['neutral']['900']};
`;

export const AuthorizedAgeLevelsContainer = styled.div`
  padding: 16px 24px;
  width: 100%;
`;
