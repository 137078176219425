import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Box } from 'components/Box';
import useAsync from 'hooks/useAsync';
import queryString from 'query-string';
import InnerHeader from './InnerHeader';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { areAllCodesCompleted } from './utils';
import MainContent from 'components/MainContent';
import PageWrapper from 'components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import EnterCodesSection from './EnterCodesSection';
import { Video } from 'components/Carousel/Carousel';
import Notes from 'components/ReliabilityTest/Notes';
import PageBackground from 'components/PageBackground';
import { LinkButton, PageHeaderWrapper } from './Styled';
import { useCallback, useEffect, useState } from 'react';
import CodingVideos from 'components/ReliabilityTest/CodingVideos';
import InstructionsModal from 'components/Modals/InstructionsModal';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';
import ConfirmationModalBody from './ConfirmationModal/ConfirmationModalBody';
import ModalContent from 'components/ReliabilityTest/CodingVideos/ModalContent';
import { ContainerSection as Section } from 'components/Reports/Reliability/View/Styled';
import {
  nextVideo,
  getTestAttemptById,
  evaluateTestAttempt
} from 'actions/testAttempts';
import {
  Title,
  LogoImg,
  Subtitle,
  TitleWrapper
} from 'components/PageHeader/Styled';

type ReliabilityTestProps = {
  /** Test Attempt ID */
  id: string;
};

type Dimension = {
  id: number;
  name: string;
  user_answer: { id: number; answer: number } | null;
  abbreviation: string;
};

type DimensionScore = {
  id: number;
  answer: number;
};

function ReliabilityTest({ id }: ReliabilityTestProps) {
  const { data, run, isPending } = useAsync();
  const [showModal, setShowModal] = useState(false);
  const [dimensionsCount, setDimensionsCount] = useState(0);
  const [currentVideo, setCurrentVideo] = useState<Video>();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [fabTooltipVisible, setFabTooltipVisible] = useState(false);
  const [userScores, setUserScores] = useState<DimensionScore[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();

  const getCurrentVideoIndex = useCallback(() => {
    return Array.prototype.findIndex.call(
      data.coding_videos,
      x => x.id === data.current_resource_id
    );
  }, [data]);

  useEffect(() => {
    run(getTestAttemptById(Number(id)));
  }, [run, id]);

  useEffect(() => {
    if (!data) {
      return;
    }

    let videoIndex = getCurrentVideoIndex();
    let loadPreviousScores: DimensionScore[] = [];
    setDimensionsCount(data.coding_videos[videoIndex].dimensions.length);
    data.coding_videos[videoIndex].dimensions.forEach(
      (dimension: Dimension) => {
        if (dimension.user_answer)
          loadPreviousScores.push({
            id: dimension.id,
            answer: dimension.user_answer.answer
          });
      }
    );
    setUserScores(loadPreviousScores);
    setCurrentVideoIndex(videoIndex);
    setCurrentVideo(data?.coding_videos[videoIndex]);
  }, [data, getCurrentVideoIndex]);

  function isLastVideo(videosLength: number) {
    return videosLength === data.coding_videos.length - 1;
  }

  function handleSubmit() {
    const videosLength = getCurrentVideoIndex();
    setShowConfirmationModal(false);
    if (isLastVideo(videosLength)) {
      run(evaluateTestAttempt(Number(id)), {
        onSuccess: () => {
          navigate(
            RouteHelpers.getPath('test-attempts-score-report', {
              id: id
            })
          );
        }
      });
    } else if (areAllCodesCompleted(dimensionsCount, userScores.length)) {
      run(nextVideo(Number(id)));
      setUserScores([]);
    }
  }

  if (isPending) {
    return <PageLoader />;
  }

  if (data.completed_at) {
    navigate(
      RouteHelpers.getPath('test-attempts-score-report', {
        id: id
      })
    );
    return null;
  }

  return (
    <MainContent maxWidth={1280}>
      <ConfirmationModal
        title={i18n.ft(messages.confirmationModal.title)}
        message={<ConfirmationModalBody />}
        dismissButtonText={i18n.ft(messages.confirmationModal.cancel)}
        confirmButtonText={i18n.ft(messages.confirmationModal.submit)}
        isOpen={showConfirmationModal}
        onConfirm={() => handleSubmit()}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
      />
      <InstructionsModal
        title={i18n.ft(messages.modal.instructionsTitle)}
        message={<ModalContent />}
        isOpen={showModal}
        confirmButton={i18n.ft(messages.modal.dismissButton)}
        onConfirm={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
      />
      <PageWrapper borderStyle>
        <PageHeaderWrapper>
          <TitleWrapper>
            <LogoImg
              src={logoCertification}
              alt={i18n.ft(messages.logoDescription)}
            />
            <Title>{data.title}</Title>
            <Subtitle>{i18n.ft(messages.subtitle)}</Subtitle>
          </TitleWrapper>
        </PageHeaderWrapper>
        <PageBackground borderStyle>
          <InnerHeader
            attempt={i18n.ft(messages.attempt, { value: data.attempt })}
            expiresOn={i18n.ft(messages.expiresOn)}
            expiresOnMobile={i18n.ft(messages.expiresOnMobile)}
            expiresAt={data.expires_at}
          />
          <Box mb={{ _: '24px', xl: '32px' }}>
            <Section
              mt="8px"
              px="48px"
              py="24px"
              mx={{ _: '0px', lg: '32px' }}
              isMobile
              borderTopRadius={isTablet ? '12px' : ''}
            >
              <CodingVideos
                coding_videos={data.coding_videos}
                currentVideoId={data.current_resource_id}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <div className="text-left">
                  <LinkButton
                    type="button"
                    onClick={() => {
                      return navigate(
                        queryString.stringifyUrl({
                          url: `${RouteHelpers.getPath(
                            'test-template-resources',
                            {
                              id: data.test_template?.id
                            }
                          )}`,
                          query: {
                            attempt_number: data.attempt,
                            attempt_id: id
                          }
                        })
                      );
                    }}
                    className={`mt-5 font-bold flex items-center gap-2 text-[#0A9CCA] hover:text-[#0889b3] transition-colors duration-200 ${isTablet ? '' : 'text-start'}`}
                    onMouseEnter={() => setFabTooltipVisible(true)}
                    onMouseLeave={() => setFabTooltipVisible(false)}
                    onFocus={() => setFabTooltipVisible(true)}
                    onBlur={() => setFabTooltipVisible(false)}
                  >
                    {i18n.ft(messages.testPrepResources)}
                    <div className="relative">
                      {fabTooltipVisible && (
                        <div className="absolute top-[-3rem] font-normal text-black left-1/2 transform -translate-x-1/2 bg-white px-4 py-2 rounded-lg shadow-lg whitespace-nowrap z-10">
                          {i18n.ft(messages.practiceVideos)}
                          <div className="absolute w-4 h-4 bg-white transform rotate-45 left-1/2 -translate-x-1/2 -bottom-2"></div>
                        </div>
                      )}
                      <div className="bg-[#0A9CCA] rounded-full p-1.5">
                        <svg
                          className="w-4 h-4 text-white"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                          />
                        </svg>
                      </div>
                    </div>
                  </LinkButton>
                </div>

                <div>
                  <LinkButton
                    type="button"
                    onClick={() => setShowModal(true)}
                    className={`mt-6 font-bold mx-auto w-full text-left md:text-end lg:text-center${
                      isTablet ? '' : 'text-start'
                    }`}
                  >
                    {i18n.ft(messages.testInstructions)}
                  </LinkButton>
                </div>
              </div>
              {currentVideo?.note ? <Notes>{currentVideo.note}</Notes> : null}
            </Section>

            <EnterCodesSection
              dimensions={data.coding_videos[currentVideoIndex].dimensions}
              testPreparationId={id}
              videoId={data.coding_videos[currentVideoIndex].id}
              testType="reliability"
              setShowConfirmationModal={setShowConfirmationModal}
              updateUserScores={setUserScores}
            />
          </Box>
        </PageBackground>
      </PageWrapper>
    </MainContent>
  );
}

export default ReliabilityTest;
