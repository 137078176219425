const PATH = 'fe.components.organization.table.results_summary';

const messages = {
  results: {
    key: `${PATH}.results`,
    defaultValue: 'Showing {{results}} of {{total}}'
  }
};

export default messages;
