const PATH = 'fe.components.certifications.certification_card';

const messages = {
  notApplies: {
    key: `${PATH}.not_applies`,
    defaultValue: 'N/A'
  },
  expires: {
    key: `${PATH}.expires`,
    defaultValue: 'Certified until'
  },
  certificate: {
    key: `${PATH}.certificate`,
    defaultValue: 'Certificate'
  },
  download: {
    key: `${PATH}.download`,
    defaultValue: 'Download'
  },
  renew: {
    key: `${PATH}.renew`,
    defaultValue: 'Renew'
  },
  showHistory: {
    key: `${PATH}.show_history`,
    defaultValue: 'Show History'
  },
  hideHistory: {
    key: `${PATH}.hide_history`,
    defaultValue: 'Hide History'
  },
  dateCertified: {
    key: `${PATH}.date_certified`,
    defaultValue: 'Date Certified'
  },
  testScore: {
    key: `${PATH}.test_score`,
    defaultValue: 'Test Score'
  },
  // Status Messages
  status: {
    active: {
      key: `${PATH}.status_message.active`,
      defaultValue: 'Active'
    },
    aboutToExpire: {
      key: `${PATH}.status_message.about_to_expire`,
      defaultValue: 'About to expire'
    },
    expired: {
      key: `${PATH}.status_message.expired`,
      defaultValue: 'Expired'
    },
    expiresOn: {
      key: `${PATH}.status_message.expires_on`,
      defaultValue: 'Certified until: {{date}}'
    },
    expiredOn: {
      key: `${PATH}.status_message.expired_on`,
      defaultValue: 'Expired on {{date}}'
    },
    daysLeft: {
      key: `${PATH}.status_message.days_left`,
      defaultValue: '{{count}} days left to renew'
    },
    renewOn: {
      key: `${PATH}.status_message.renew_on`,
      defaultValue: 'Renewable on: {{date}}'
    },
    contact: {
      key: `${PATH}.status_message.contact`,
      defaultValue:
        'To renew, <a href="https://teachstone.com/support/">contact us</a>'
    }
  },
  practicing: {
    key: `${PATH}.practicing`,
    defaultValue: 'Start Practicing Now'
  },
  transitioned: {
    key: `${PATH}.transitioned`,
    defaultValue: 'Transitioned to Second Edition'
  },
  observableAgeLevels: {
    key: `${PATH}.observable_2008_age_levels`,
    defaultValue: 'Observable CLASS 2008 Age Levels'
  },
  trainableAgeLevelsSingle: {
    key: `${PATH}.trainable_2008_age_levels`,
    defaultValue: 'Able to train on CLASS 2008 {{ageLevel}}'
  },
  trainableAgeLevelsMultiple: {
    key: `${PATH}.trainable_2008_age_levels`,
    defaultValue:
      'Able to train on CLASS 2008 {{ageLevel}} and {{ageLevelAdditional}}'
  },
  trainableAgeLevelsCoaching: {
    key: `${PATH}.trainable_2008_coaching_age_levels`,
    defaultValue: 'Able to Deliver {{ageLevel}} CLASS Group Coaching'
  }
};

export default messages;
