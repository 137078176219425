import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { roleToText } from 'helpers/role';
import { dcRoles, statusList } from '../../utils';
import { composeUser } from 'pages/Organization/Members/utils';
import { resendInvitations } from 'actions/customers';
import { Checkbox } from 'components/Organization/Checkbox';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { Pagination } from 'components/Organization/Pagination';
import { TextButton } from 'components/Organization/TextButton';
import { StatusBadge } from 'components/Organization/StatusBadge';
import { toastError, toastSuccess } from 'components/Organization/Toast';
import { useMutation } from '@tanstack/react-query';
import { useTableState } from '../utils';

import {
  Table,
  ColumnFilter,
  ResultsSummary
} from 'components/Organization/Table';

import {
  removeUsersFromSubscription,
  useGetSubscriptionMembershipTrackers
} from 'actions/membershipTrackers';

type QueryParams = {
  page: number;
  per_page: number;
  query: string;
  role: string[];
  status: string[];
  sort_by: string | null;
  sort_dir: 'asc' | 'desc' | null;
};

interface DCUsersTableProps {
  subscriptionId: number;
  searchQuery: string;
}

export function DCUsersTable({
  searchQuery,
  subscriptionId
}: DCUsersTableProps) {
  const { isPending, mutate: triggerResendInvitations } = useMutation({
    mutationFn: ({
      subscriptionId,
      userEmails
    }: {
      subscriptionId: number;
      userEmails: string[];
    }) => resendInvitations(subscriptionId, userEmails),
    onSuccess: () => {
      setSelected([]);
      toastSuccess({ message: i18n.ft(messages.invitationsSent) });
    }
  });

  const { isPending: isUnsubscribing, mutate: triggerUnsubscribeUsers } =
    useMutation({
      mutationFn: ({
        subscriptionId,
        trackerIds
      }: {
        subscriptionId: number;
        trackerIds: number[];
      }) => removeUsersFromSubscription(subscriptionId, trackerIds),
      onSuccess: (data, { trackerIds }) => {
        setSelected([]);
        toastSuccess({
          message: i18n.ft(messages.unsubscribeSuccess, {
            count: trackerIds.length
          })
        });
        refetch();
      },
      onError: () => {
        toastError({ message: i18n.ft(messages.unsubscribeError) });
      }
    });

  const {
    state,
    setState: setQueryState,
    setPage: setQueryPage
  } = usePaginatedSearch<QueryParams>({
    page: 1,
    per_page: 20,
    query: searchQuery,
    role: [],
    status: [],
    sort_by: null,
    sort_dir: null
  });

  const {
    selected,
    setSelected,
    toggleSelected,
    setState,
    setPage,
    setSortBy
  } = useTableState({
    state,
    search: searchQuery,
    setState: setQueryState,
    setPage: setQueryPage
  });

  const { data, isSuccess, refetch } = useGetSubscriptionMembershipTrackers(
    subscriptionId,
    state
  );

  const handleHeaderSelect = () => {
    if (!data || !data.membership_trackers) {
      return;
    }

    if (selected.length <= 0) {
      const allTrackerIdsOnPage = data.membership_trackers.map(
        tracker => tracker.id
      );
      setSelected(allTrackerIdsOnPage);
    } else {
      setSelected([]);
    }
  };

  function handleResendInvitation() {
    if (isPending) {
      return;
    }

    const trackers = data?.membership_trackers ?? [];

    const userEmails = trackers
      .filter(tracker => selected.includes(tracker.id))
      .map(tracker => tracker.email);

    triggerResendInvitations({ subscriptionId, userEmails });
  }

  function handleUnsubscribeUsers() {
    if (isUnsubscribing) {
      return;
    }

    triggerUnsubscribeUsers({ subscriptionId, trackerIds: selected });
  }

  const trackers = data?.membership_trackers ?? [];

  return (
    <>
      <ResultsSummary
        perPage={20}
        total={data?.pagination.total_count ?? 0}
        currentPage={data?.pagination.current_page ?? 1}
      />

      <Table
        isLoading={!isSuccess}
        sortBy={state.sort_by}
        sortDir={state.sort_dir}
        onSort={setSortBy}
        headers={[
          {
            content: (
              <Checkbox
                checked={selected.length > 0}
                indeterminate={
                  selected.length > 0 && selected.length !== trackers.length
                }
                onChange={handleHeaderSelect}
              />
            ),
            accessor: 'checkbox'
          },
          {
            content: i18n.ft(messages.columns.nameAndEmail),
            accessor: 'name',
            sortable: true
          },
          {
            content: (
              <ColumnFilter
                options={dcRoles}
                values={state.role}
                onChange={role => setState({ role })}
                name={i18n.ft(messages.columns.role)}
              />
            ),
            accessor: 'role'
          },
          {
            content: (
              <ColumnFilter
                options={statusList}
                values={state.status}
                onChange={status => setState({ status })}
                name={i18n.ft(messages.columns.status)}
              />
            ),
            accessor: 'status'
          },
          {
            content: i18n.ft(messages.columns.invitedOn),
            accessor: 'invited_on',
            sortable: true
          }
        ]}
        data={trackers.map(tracker => ({
          selected: selected.includes(tracker.id),
          checkbox: (
            <Checkbox
              checked={selected.includes(tracker.id)}
              onChange={() => toggleSelected(tracker.id)}
            />
          ),
          name: composeUser(tracker.user_id, tracker.name, tracker.email),
          role: roleToText(tracker.role),
          status: <StatusBadge status={tracker.invite_status} />,
          invited_on: moment(tracker.invited_on).format('MM/DD/YYYY')
        }))}
        actions={
          selected.length > 0 && (
            <div className="flex gap-6">
              <TextButton
                inverted
                size="small"
                onClick={handleResendInvitation}
              >
                <i className="fa-regular fa-paper-plane-top mr-2" />
                <span>
                  {i18n.ft(messages.actions.resendInvite, {
                    count: selected.length
                  })}
                </span>
              </TextButton>
              <TextButton
                inverted
                size="small"
                onClick={handleUnsubscribeUsers}
              >
                <i className="fa-regular fa-folder-arrow-down mr-2" />
                <span>
                  {i18n.ft(messages.actions.unsubscribe, {
                    count: selected.length
                  })}
                </span>
              </TextButton>
              <TextButton inverted size="small" onClick={() => setSelected([])}>
                <span>{i18n.ft(messages.actions.deselect)}</span>
              </TextButton>
            </div>
          )
        }
      />

      <div className="mt-6 flex justify-center">
        <Pagination
          page={state.page}
          onPageChange={setPage}
          total={data?.pagination.total_pages ?? 0}
        />
      </div>
    </>
  );
}
