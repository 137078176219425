import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { SecondaryButton } from 'components/Organization/SecondaryButton';
import DatePicker from 'components/Reports/Observation/DateRangePicker/DatePicker';
import { useState, useEffect } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';

const options = [
  { value: 'current', label: i18n.ft(messages.current) },
  { value: 'custom', label: i18n.ft(messages.custom) }
];

interface DateRangeModalProps {
  /** Modal open state. */
  open: boolean;

  /** Callback fired when modal is closed. */
  onClose: () => void;

  /** Callback fired when deletion is confirmed. */
  onConfirm: (startDate: Date, endDate: Date, isCustom: boolean) => void;

  initialStartDate: Date | null;
  initialEndDate: Date | null;

  getCurrentSchoolYear: () => { start: Date; end: Date };
}

function DateRangeModal({
  open,
  onClose,
  onConfirm,
  initialStartDate,
  initialEndDate,
  getCurrentSchoolYear
}: DateRangeModalProps) {
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const { start, end } = getCurrentSchoolYear();

  useEffect(() => {
    if (selectedOption.value === 'current') {
      setStartDate(start);
      setEndDate(end);
    }
  }, [selectedOption, start, end]);

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    if (startDate && endDate) {
      const isCustom = selectedOption.value === 'custom';
      onConfirm(startDate, endDate, isCustom);
    }
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    if (selectedOption.value !== 'custom') {
      setSelectedOption(options[1]);
    }
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    if (selectedOption.value !== 'custom') {
      setSelectedOption(options[1]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="text-black relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-xl bg-white p-4 rounded-lg">
          <DialogTitle className="font-sans font-bold text-xl m-0">
            {i18n.ft(messages.title)}
          </DialogTitle>
          <p className="font-sans mt-2 mb-8">{i18n.ft(messages.subtitle)}</p>
          <div className="flex items-center justify-between border border-navy-300 rounded">
            <Listbox value={selectedOption} onChange={setSelectedOption}>
              <div className="relative">
                <ListboxButton className="relative w-full cursor-default py-2 pl-3 pr-10 text-left bg-white">
                  {selectedOption.label}
                  <div className="pl-2 fa-solid fa-chevron-down fa-sm cursor-pointer"></div>
                </ListboxButton>
                <ListboxOptions
                  modal={false}
                  transition
                  className="absolute w-full min-w-40 mt-1 overflow-auto bg-white border border-navy-300 rounded max-h-60 focus:outline-none"
                >
                  {options.map(option => (
                    <ListboxOption
                      key={option.value}
                      value={option}
                      className="cursor-pointer select-none relative p-2 text-black hover:bg-action-light"
                    >
                      {option.label}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </Listbox>
            <div className="flex items-center justify-between">
              <DatePicker
                selected={startDate}
                placeholderText="MM/DD/YYYY"
                onChange={handleStartDateChange}
              />

              <div className="py-2 pr-3">-</div>

              <DatePicker
                selected={endDate}
                placeholderText="MM/DD/YYYY"
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 pt-8">
            <SecondaryButton onClick={handleCancel}>
              {i18n.ft(messages.cancel)}
            </SecondaryButton>
            <PrimaryButton onClick={handleConfirm}>
              {i18n.ft(messages.update)}
            </PrimaryButton>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}

export default DateRangeModal;
