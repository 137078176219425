import i18n from 'helpers/i18n';
import messages from './messages';

interface ResultsSummaryProps {
  /** The total number of results. */
  total: number;

  /** The number of results per page. */
  perPage: number;

  /** The current page number. */
  currentPage: number;
}

export function ResultsSummary({
  total,
  perPage,
  currentPage
}: ResultsSummaryProps) {
  const leftValue = 1 + perPage * (currentPage - 1);
  const rightValue = Math.min(perPage * currentPage, total);
  const results = total <= 1 ? total : `${leftValue}-${rightValue}`;

  return (
    <div className="text-sm text-charcoal-500 mt-4 mb-2">
      {i18n.ft(messages.results, { results, total })}
    </div>
  );
}
